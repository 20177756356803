import React, { useContext } from 'react';
import { navigate, PageProps } from 'gatsby';
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import Layout from '@/components/Layout';
import UserContext from '@/components/UserContext';
import styled from '@emotion/styled';

const LoginWrapper = styled.div`
  position: relative;
`;
const AuthenticatorWrapper = styled(AmplifyAuthenticator)`
  --container-height: min(100vh, 600px);
`;

const Login: React.FC<PageProps> = () => {
  const userContext = useContext(UserContext);
  if (userContext) {
    navigate('/');
  }
  return (
    <Layout>
      <LoginWrapper>
        <AuthenticatorWrapper>
          <AmplifySignIn slot="sign-in" hideSignUp usernameAlias="email" />
          <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
        </AuthenticatorWrapper>
      </LoginWrapper>
    </Layout>
  );
};

export default Login;
